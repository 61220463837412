@keyframes textGlow {
    0%, 100% {
        text-shadow: 0 0 5px #fff, 0 0 10px #ff6347, 0 0 15px #ff6347;
    }
    50% {
        text-shadow: 0 0 5px #fff, 0 0 10px #ffd700, 0 0 15px #ffd700;
    }
}

@keyframes imageBounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.animate-text-glow {
    animation: textGlow 2s infinite;
}

.animate-image-bounce {
    animation: imageBounce 2s ease-in-out infinite;
}
