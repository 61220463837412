.services-page .service-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services-page .service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.services-page .choose-us-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Float Animation */
.float-animation {
  transition: transform 0.3s ease-in-out;
}

.float-animation:hover {
  transform: translateY(-10px);
}

/* Ripple Effect for Why Choose Us Section */
.choose-us-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.choose-us-card:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: transform 0.6s ease-out;
  z-index: 1;
}

.choose-us-card:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

/* Hover Scaling */
.service-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}

/* Button Pulse Animation */
.pulse-button {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
