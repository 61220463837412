/* HeroPage.css */

@keyframes floaty {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-floaty {
  animation: floaty 5s ease-in-out infinite;
}

.animate-slide-in-down {
  animation: slide-in-down 1s ease-out forwards;
}

.animate-slide-in-up {
  animation: slide-in-up 1s ease-out forwards;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.bg-gradient-to-t {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.shadow-2xl {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}
/* HeroPage.css */

@keyframes floaty {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-floaty {
  animation: floaty 5s ease-in-out infinite;
}

.animate-slide-in-down {
  animation: slide-in-down 1s ease-out forwards;
}

.animate-slide-in-up {
  animation: slide-in-up 1s ease-out forwards;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.bg-gradient-to-t {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.shadow-2xl {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}
