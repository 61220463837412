@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.in-view {
  opacity: 1 !important; /* Ensures content is visible when in view */
}

.in-view .slide-up {
  animation: slideUp 1s ease-out forwards;
}

.card-hover {
  animation: pulse 2s infinite ease-in-out;
}

.animate-slide-up {
  animation: slideUp 0.8s ease-out forwards;
}

.animate-scale-up {
  animation: scaleUp 0.8s ease-out forwards;
}

.animate-pulse {
  animation: pulse 2s infinite;
}
